const aggregationLevels = {
  ITEMTYPE: 'item_type',
  CLASS: 'class',
  DESCRIPTION: 'description',
  GECNO: 'gec_no',
  GWTTNO: 'gwtt_no',
};

const groupBy = (data, keyGetter) => {
  const map = new Map();
  data.forEach((item) => {
    const key = keyGetter(item);
    console.debug('In dataHelper groupBy: key', key);
    const collection = map.get(key);
    console.debug('In dataHelper groupBy: collection', collection);
    if (!collection) {
      map.set(key, {
        total_days: parseInt(item.total_days, 10),
        total_items: parseInt(item.total_items, 10),
        days_rented: parseInt(item.days_rented, 10),
        total_rented: item.rented === '1' ? 1 : 0,
        rented: item.rented === '1' ? [item.gwtt_no] : [],
        on_hold: item.on_hold === '1' ? [item.gwtt_no] : [],
        in_yard: item.in_yard === '1' ? [item.gwtt_no] : [],
      });
      console.debug('In dataHelper groupBy: map get key', map.get(key));
    } else {
      collection.total_days += parseInt(item.total_days, 10);
      collection.total_items += parseInt(item.total_items, 10);
      collection.days_rented += parseInt(item.days_rented, 10);
      collection.total_rented += item.rented === '1' ? 1 : 0;
      if (item.rented === '1') {
        collection.rented.push(item.gwtt_no);
      }
      if (item.on_hold === '1') {
        collection.on_hold.push(item.gwtt_no);
      }

      if (item.in_yard === '1') {
        collection.in_yard.push(item.gwtt_no);
      }
    }
  });
  const returnArray = [];
  map.forEach((value, key) => {
    returnArray.push({ ...JSON.parse(key), ...value });
  });
  console.debug('In dataHelper groupBy: returnArray', returnArray);
  return returnArray;
};

const aggregateData = (data, aggregationLevel) => {
  let aggregatedData = data;
  if (aggregatedData) {
    switch (aggregationLevel) {
      case aggregationLevels.GWTTNO:
        aggregatedData = groupBy(data, (item) =>
          JSON.stringify({
            item_type: item.item_type,
            class: item.class,
            description: item.description,
            gec_no: item.gec_no,
            gwtt_no: item.gwtt_no,
          }),
        );
        break;
      case aggregationLevels.GECNO:
        aggregatedData = groupBy(data, (item) =>
          JSON.stringify({
            item_type: item.item_type,
            class: item.class,
            description: item.description,
            gec_no: item.gec_no,
            gwtt_no: item.gwtt_no,
          }),
        );
        break;
      case aggregationLevels.DESCRIPTION:
        aggregatedData = groupBy(data, (item) =>
          JSON.stringify({
            item_type: item.item_type,
            class: item.class,
            description: item.description,
            gec_no: '',
            gwtt_no: '',
          }),
        );
        break;
      case aggregationLevels.CLASS:
        aggregatedData = groupBy(data, (item) =>
          JSON.stringify({
            item_type: item.item_type,
            class: item.class,
            description: '',
            gec_no: '',
            gwtt_no: '',
          }),
        );
        break;
      default:
        aggregatedData = groupBy(data, (item) =>
          JSON.stringify({
            item_type: item.item_type,
            class: item.class,
            description: '',
            gec_no: '',
            gwtt_no: '',
          }),
        );
        break;
    }
  }
  console.debug('In dataHelper aggregateData: aggregatedData', aggregatedData);
  return aggregatedData;
};

const applyFilters = (
  initialData,
  itemTypeFilter,
  classFilter,
  descriptionFilter,
  gecFilter,
  gwttFilter,
) => {
  let aggregation = null;
  let filteredData = initialData;
  console.debug(initialData);
  if (initialData) {
    console.debug(
      `length of data to start: ${filteredData.length}, Aggregation Level: ${aggregation}`,
    );
    if (gwttFilter.length !== 0) {
      filteredData = filteredData.filter((element) =>
        gwttFilter.includes(element.gwtt_no.toString()),
      );
      if (!aggregation) {
        aggregation = aggregationLevels.GWTTNO;
      }
    }
    console.debug(`After GWTT Filter: ${filteredData.length}, Aggregation Level: ${aggregation}`);
    if (gecFilter.length !== 0) {
      filteredData = filteredData.filter((element) =>
        gecFilter.includes(element.gec_no.toString()),
      );
      if (!aggregation) {
        aggregation = aggregationLevels.GWTTNO;
      }
    }
    console.debug(`After GEC Filter: ${filteredData.length}, Aggregation Level: ${aggregation}`);
    if (descriptionFilter.length !== 0) {
      filteredData = filteredData.filter((element) =>
        descriptionFilter.includes(element.description),
      );
      if (!aggregation) {
        aggregation = aggregationLevels.GECNO;
      }
    }
    console.debug(
      `After Description Filter: ${filteredData.length}, Aggregation Level: ${aggregation}`,
    );
    if (classFilter.length !== 0) {
      filteredData = filteredData.filter((element) => classFilter.includes(element.class));
      if (!aggregation) {
        aggregation = aggregationLevels.DESCRIPTION;
      }
    }
    console.debug(`After Class Filter: ${filteredData.length}, Aggregation Level: ${aggregation}`);
    if (itemTypeFilter.length !== 0) {
      filteredData = filteredData.filter((element) => itemTypeFilter.includes(element.item_type));
      if (!aggregation) {
        aggregation = aggregationLevels.CLASS;
      }
    }
    console.debug(
      `After Item Type Filter: ${filteredData.length}, Aggregation Level: ${aggregation}`,
    );
    if (!aggregation) {
      aggregation = aggregationLevels.ITEMTYPE;
    }
    return { filteredData, aggregation };
  }
  return { filteredData, aggregation };
};

export { aggregationLevels, aggregateData, applyFilters };
